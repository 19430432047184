<template>
  <div class="columns is-centered">
    <div class="column is-12">
      <div class="column" style="padding-botton:100">
        <p class="subtitle is-3" style="text-align:center;">Conferir dados preenchidos</p>
      </div>
      <div class="columns">
        <div class="column is-half">
          <div>
            <p class="subtitle is-4 has-text-weight-semibold" style="margin-bottom: 10px;">Infração</p>
          </div>
          <div>
            <span class="has-text-weight-semibold">Placa:</span> <span>{{ infracao.placa }}</span>
          </div>
          <div>
            <span class="has-text-weight-semibold">AIT:</span> <span>{{ infracao.ait }}</span>
          </div>
          <div>
            <span class="has-text-weight-semibold">Enquadramento:</span> <span>{{ infracao.enquadramentoCompleto }}</span>
          </div>
          <div>
            <span class="has-text-weight-semibold">Local:</span> <span>{{ infracao.local }}</span>
          </div>
          <div>
            <span class="has-text-weight-semibold">Data/Hora da Infração:</span> <span>{{ dataHoraInfracao }}</span>
          </div>
        </div>
        <div class="column is-half">
          <div>
          <p class="subtitle is-4 has-text-weight-semibold" style="margin-bottom: 10px;">Interessado</p>
          </div>
          <div>
            <span class="has-text-weight-semibold">Nome:</span> <span>{{ recurso.nomeInteressado }}</span>
          </div>
          <div>
            <span class="has-text-weight-semibold">Documento:</span> <span>{{ recurso.documento }}</span>
          </div>
          <div>
            <span v-show="recurso.semDocResponsabilidade"><span class="has-text-weight-semibold">Documento de Responsabilidade: </span>Não possuo</span>
          </div>
          <div>
            <span v-show="recurso.semDocComprobatorio"><span class="has-text-weight-semibold">Documento Comprobatório: </span>Não possuo</span>
          </div>
        </div>
      </div>
      <div class="is-size-5 div-pad-2">
        <p class="subtitle is-4 has-text-weight-semibold">Documentos</p>
      </div>
      <div class="columns is-centered div-pad-1">
        <div class="b-col">
          <div class="column">
            <div class="is-size-6">
              Documento de identificação
            </div>
            <img v-if="files.docIdentificacao.type !== 'application/pdf'" v-show="files.docIdentificacao.b64 !== null" :src="files.docIdentificacao.b64" class="mini-image">
            <object v-else type="application/pdf" :data="files.docIdentificacao.b64" class="mini-pdf" />
          </div>
        </div>
        <div class="b-col">
          <div class="column">
            <div class="is-size-6">
              Documento de Notificação ou Penalidade
            </div>
            <img v-if="files.docNotifOuPenalidade.type !== 'application/pdf'" v-show="files.docNotifOuPenalidade.b64 !== null" :src="files.docNotifOuPenalidade.b64" class="mini-image">
            <object v-else type="application/pdf" :data="files.docNotifOuPenalidade.b64" class="mini-pdf" />
          </div>
        </div>
        <div class="b-col">
          <div class="column">
            <div class="is-size-6">
              Formulário Assinado
            </div>
            <img v-if="files.formularioAssinado.type !== 'application/pdf'" v-show="files.formularioAssinado.b64 !== null" :src="files.formularioAssinado.b64" class="mini-image">
            <object v-else type="application/pdf" :data="files.formularioAssinado.b64" class="mini-pdf" />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="b-col">
          <div v-show="!recurso.semDocResponsabilidade && files.docResponsabilidade.b64 !== null" class="column">
            <div class="is-size-6">
              Documento de responsabilidade
            </div>
            <div v-if="files.docResponsabilidade !== null">
              <img v-if="files.docResponsabilidade.type !== 'application/pdf'" :src="files.docResponsabilidade.b64" class="mini-image">
              <object v-else type="application/pdf" :data="files.docResponsabilidade.b64" class="mini-pdf" />
            </div>
          </div>
        </div>
          <div class="b-col">
            <div v-show="!recurso.semDocComprobatorio && files.docComprobatorio.b64 !== null" class="column">
              <div class="is-size-6">
                Documento Comprobatório
              </div>
              <div v-if="files.docComprobatorio !== null">
                <img v-if="files.docComprobatorio.type !== 'application/pdf'" :src="files.docComprobatorio.b64" class="mini-image">
                <object v-else type="application/pdf" :data="files.docComprobatorio.b64" class="mini-pdf" />
              </div>
            </div>
          </div>
      </div>
      <div class="field">
        <b-field>
          <b-checkbox v-model="accept" name="flag-terms">
            <div class="has-text-weight-semibold">
              Declaro, sob pena da lei, que assumo total responsabilidade pela veracidade das informações aqui prestadas.
            </div>
          </b-checkbox>
        </b-field>
        <span
          for="accept"
          class="error"
          v-if="$v.accept.$invalid"
        >
          Você se responsabiliza pelas informações inseridas?
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { sameAs } from 'vuelidate/lib/validators'
import { format } from 'date-fns'
export default {
  name: 'StepConfirmar',
  props: {
    infracao: {
      type: Object,
      required: true
    },
    recurso: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      accept: false,
      files: {
        docIdentificacao: {
          b64: null,
          type: null
        },
        docNotifOuPenalidade: {
          b64: null,
          type: null
        },
        formularioAssinado: {
          b64: null,
          type: null
        },
        docResponsabilidade: {
          b64: null,
          type: null
        },
        docComprobatorio: {
          b64: null,
          type: null
        }
      }
    }
  },
  validations: {
    accept: {
      sameAs: sameAs(() => true)
    }
  },
  computed: {
    dataHoraInfracao () {
      return format(new Date(this.infracao.dataHoraInfracao), 'dd/MM/yyyy HH:mm')
    }
  },
  async beforeMount () {
    if (!this.recurso.semDocResponsabilidade) {
      this.files.docResponsabilidade.b64 = await getFileB64(this.recurso.imagens.docResponsabilidade)
      this.files.docResponsabilidade.type = await getFileType(this.recurso.imagens.docResponsabilidade)
    }

    if (!this.recurso.semDocComprobatorio) {
      this.files.docComprobatorio.b64 = await getFileB64(this.recurso.imagens.docComprobatorio)
      this.files.docComprobatorio.type = await getFileType(this.recurso.imagens.docComprobatorio)
    }

    this.files.docIdentificacao.b64 = await getFileB64(this.recurso.imagens.docIdentificacao)
    this.files.docIdentificacao.type = await getFileType(this.recurso.imagens.docIdentificacao)

    this.files.docNotifOuPenalidade.b64 = await getFileB64(this.recurso.imagens.docNotifOuPenalidade)
    this.files.docNotifOuPenalidade.type = await getFileType(this.recurso.imagens.docNotifOuPenalidade)

    this.files.formularioAssinado.b64 = await getFileB64(this.recurso.imagens.formularioAssinado)
    this.files.formularioAssinado.type = await getFileType(this.recurso.imagens.formularioAssinado)
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        if (!this.$v.$invalid) {
          resolve(true)
        }
      })
    }
  }
}

function getFileB64 (file) {
  if (file != null) {
    return window.URL.createObjectURL(file)
  }

  return null
}

function getFileType (file) {
  if (file != null) {
    return file.type
  }

  return null
}

</script>
<style>
  .div-pad-1 {
    padding-bottom: 5px;
  }

  .div-pad-2 {
    padding-top: 20px;
    padding-bottom: 5px;
  }

  .mini-image {
    max-width: 256px;
    max-height: 512px;
  }

  .mini-pdf {
    width: 300px;
    height: 400px;
  }
</style>
